// center vertically and/or horizontally an absolute positioned element

@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -ms-transform: ranslateX(-50%) translateY(-50%); /* IE 9 */
		-webkit-transform: ranslateX(-50%) translateY(-50%); /* Safari 3-8 */
		transform: ranslateX(-50%) translateY(-50%);
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    -ms-transform: translateX(-50%); /* IE 9 */
		-webkit-transform: translateX(-50%); /* Safari 3-8 */
		transform: translateX(-50%);
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    -ms-transform: translateY(-50%); /* IE 9 */
		-webkit-transform: translateY(-50%); /* Safari 3-8 */
		transform: translateY(-50%);
  }
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// border radius

@mixin border-radius($radius:.25em) {
  border-radius: $radius;
}